import helpers from "./helpers";

const PROD_AU_SHOP_DOMAIN = "lee-mathews.myshopify.com";
const PROD_US_SHOP_DOMAIN = "lee-mathews-us.myshopify.com";
const DEV_SHOP_DOMAIN = "lm-development-1.myshopify.com";

const publicAccessToken = {
    [PROD_AU_SHOP_DOMAIN]: "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",
    [PROD_US_SHOP_DOMAIN]: "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",
    [DEV_SHOP_DOMAIN]: "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",
};

const storefrontAccessToken = {
    [PROD_AU_SHOP_DOMAIN]: "4c3b429deed902c477752c8d96061b65",
    [PROD_US_SHOP_DOMAIN]: "f022de78d2fa9c2c41deca687c1c773f",
    [DEV_SHOP_DOMAIN]: "70c168835b787a73a439f9a54018f3c8",
};

export default {
    getPublicAccessToken() {
        return publicAccessToken[helpers.getShopDomain()];
    },

    getStorefrontToken() {
        return storefrontAccessToken[helpers.getShopDomain()];
    },

    getXhrRequestConfig() {
        // XHR request header is required to catch certain errors e.g. adding over quantity available
        // https://community.shopify.com/c/Shopify-Design/AJAX-POST-cart-add-js-NEVER-returns-422-only-200-OK-on/m-p/375736
        // https://stackoverflow.com/questions/17478731/whats-the-point-of-the-x-requested-with-header
        return {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            },
        };
    },

    getSearchspringBaseUrl() {
        return `https://${window.searchspringConfig.siteId}.a.searchspring.io/`;
    },

    getAdminGraphqlBaseUrl() {
        return `${helpers.getDomain()}/admin/api/2024-10/graphql.json`;
    },

    getStorefrontGraphqlBaseUrl() {
        return `${helpers.getDomain()}/api/2024-10/graphql.json`;
    }
};

